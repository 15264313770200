.drop {
  margin: 96px 12px 96px 12px;
  min-width: 400px;
}
.drop:focus {
  outline: none;
}
.text {
  text-align: center;
}
div.card {
  border-radius: 32px;
  box-shadow: none;
  padding-top: 8px;
}
