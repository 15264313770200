.FileRow_chip__1xex2 {
  margin: 4px 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-animation: FileRow_fall__2FBF0 0.5s 0.125s linear both;
  animation: FileRow_fall__2FBF0 0.5s 0.125s linear both;
}
.FileRow_error__1-qE6 {
  position: relative;
  -webkit-animation: FileRow_shake__Nrjzp 0.65s linear both;
  animation: FileRow_shake__Nrjzp 0.65s linear both;
}
div.FileRow_spinner__JWCpg {
  margin-left: 6px;
  margin-right: -6px;
}
.FileRow_busy__2pBPY {
  -webkit-animation: FileRow_spin__zsFNt 1s linear infinite;
  animation: FileRow_spin__zsFNt 1s linear infinite;
}
.FileRow_labelContainer__2WQeV {
  display: -webkit-flex;
  display: flex;
}
.FileRow_labelName__1iv_f {
  margin: 0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.FileRow_labelSize__QGK_z {
  margin: 0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-flex;
  display: flex;
}
.FileRow_labelStatus__3-1rJ {
  margin-left: 12px;
}
.FileRow_label__19_Xs {
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.FileRow_labelLeft__3PhF- {
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.FileRow_labelRight__1fnMB {
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@-webkit-keyframes FileRow_spin__zsFNt {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
@keyframes FileRow_spin__zsFNt {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
@-webkit-keyframes FileRow_shake__Nrjzp {
  10%,
  90% {
    -webkit-transform: translateX(-0.0625em);
    transform: translateX(-0.0625em);
  }
  20%,
  80% {
    -webkit-transform: translateX(0.125em);
    transform: translateX(0.125em);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-0.25em);
    transform: translateX(-0.25em);
  }
  40%,
  60% {
    -webkit-transform: translateX(0.25em);
    transform: translateX(0.25em);
  }
}
@keyframes FileRow_shake__Nrjzp {
  10%,
  90% {
    -webkit-transform: translateX(-0.0625em);
    transform: translateX(-0.0625em);
  }
  20%,
  80% {
    -webkit-transform: translateX(0.125em);
    transform: translateX(0.125em);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-0.25em);
    transform: translateX(-0.25em);
  }
  40%,
  60% {
    -webkit-transform: translateX(0.25em);
    transform: translateX(0.25em);
  }
}
@-webkit-keyframes FileRow_fall__2FBF0 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes FileRow_fall__2FBF0 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.Upload_drop__2XTJi {
  margin: 96px 12px 96px 12px;
  min-width: 400px;
}
.Upload_drop__2XTJi:focus {
  outline: none;
}
.Upload_text__zoT5P {
  text-align: center;
}
div.Upload_card__1_4Wp {
  border-radius: 32px;
  box-shadow: none;
  padding-top: 8px;
}

.App_app__3kKlL {
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

body {
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

